<template>
<div>
    <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid pt-5">
        <div class="col-6">
            <div class="kt-portlet kt-portlet--height-fluid">
                <div class="kt-portlet__head kt-portlet__head--noborder">
                    <div class="kt-portlet__head-label">
                        <span class="kt-portlet__head-icon"><i class="flaticon-warning-sign" /></span>
                        <h3 class="kt-portlet__head-title">
                            Unsubscribe from SyncGrades Emails
                        </h3>
                    </div>
                </div>
                <div class="kt-portlet__body">
                    <div class="kt-portlet__content">
                        <div class="form-group">
                            <div class="input-group">
                                <input
                                    v-model="email"
                                    type="email"
                                    class="form-control"
                                    aria-describedby="emailHelp"
                                    placeholder="Enter email to unsubscribe"
                                >
                                <div class="input-group-append">
                                    <button
                                        class="btn"
                                        :class="{
                                            'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': saving,
                                            'btn-primary': validEmail && !success,
                                            'btn-warning': !validEmail && !success,
                                            'btn-success': success,
                                        }"
                                        type="button"
                                        :disabled="saving || !validEmail"
                                        @click.stop.prevent="unsubscribe"
                                    >
                                        <i
                                            v-if="success"
                                            class="fa fa-check kt-font-light"
                                        />
                                        {{ success ? 'Unsubscribed' : 'Unsubscribe' }}
                                    </button>
                                </div>
                            </div>
                            <span
                                v-if="error"
                                class="form-text text-muted kt-font-danger kt-font-bold"
                            >{{ error }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import * as network from '../network';

export default {
    name: 'EmailUnsubscribe',
    components: {
    },
    data() {
        return {
            email: null,
            saving: false,
            success: false,
            error: null,
        };
    },
    computed: {
        token() {
            return this.$route.params.token || 'missing';
        },
        validEmail() {
            const { email } = this;
            if (!email) return false;
            const valid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return valid.test(email.toLowerCase());
        },
    },
    mounted() {

    },
    methods: {
        unsubscribe() {
            const v = this;
            if (v.saving) return;

            const { email, token, validEmail } = v;
            if (!validEmail) return;

            const params = {
                url: {
                    email: email.toLowerCase(),
                    token,
                },
            };

            network.communication.unsubscribeEmail(params, (err, res) => {
                v.saving = false;
                if (err) {
                    v.success = false;
                    v.error = 'Unable to unsubscribe. Please try again later';
                    return;
                }

                const { status } = res;
                if (status === 'success') {
                    v.success = true;
                    v.error = null;
                } else {
                    v.success = false;
                    v.error = status;
                }
            });
        },
    },
};

</script>
