var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pt-5",
      },
      [
        _c("div", { staticClass: "col-6" }, [
          _c("div", { staticClass: "kt-portlet kt-portlet--height-fluid" }, [
            _vm._m(0),
            _c("div", { staticClass: "kt-portlet__body" }, [
              _c("div", { staticClass: "kt-portlet__content" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("div", { staticClass: "input-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email,
                          expression: "email",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "email",
                        "aria-describedby": "emailHelp",
                        placeholder: "Enter email to unsubscribe",
                      },
                      domProps: { value: _vm.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.email = $event.target.value
                        },
                      },
                    }),
                    _c("div", { staticClass: "input-group-append" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn",
                          class: {
                            "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                              _vm.saving,
                            "btn-primary": _vm.validEmail && !_vm.success,
                            "btn-warning": !_vm.validEmail && !_vm.success,
                            "btn-success": _vm.success,
                          },
                          attrs: {
                            type: "button",
                            disabled: _vm.saving || !_vm.validEmail,
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.unsubscribe.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm.success
                            ? _c("i", {
                                staticClass: "fa fa-check kt-font-light",
                              })
                            : _vm._e(),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.success ? "Unsubscribed" : "Unsubscribe"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm.error
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "form-text text-muted kt-font-danger kt-font-bold",
                        },
                        [_vm._v(_vm._s(_vm.error))]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "kt-portlet__head kt-portlet__head--noborder" },
      [
        _c("div", { staticClass: "kt-portlet__head-label" }, [
          _c("span", { staticClass: "kt-portlet__head-icon" }, [
            _c("i", { staticClass: "flaticon-warning-sign" }),
          ]),
          _c("h3", { staticClass: "kt-portlet__head-title" }, [
            _vm._v(" Unsubscribe from SyncGrades Emails "),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }